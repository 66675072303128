.search {
  input {
    border: none;
    border-bottom: solid 1px $border-color;
    outline: none;
    padding-top: 5px;
    width: 150px;
  }

  span {
    color: $bookers-color;
    font-size: 12px;
    padding-right: 8px;
  }

  .search-results {
    background-color: #fff;
    border: solid 1px $border-color;
    border-radius: 0px;
    box-shadow: 0px 5px 15px 0px rgba(50, 50, 50, 0.25);
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    margin-top: -1px;
    padding: 0px 5px 0px 5px;
    position: absolute;
    width: auto;
    z-index: 9999;

    button {
      padding: 5px;
      text-align: left;

      .search-result {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        div {
          &:nth-of-type(2) {
            margin-left: 10px;
          }
        }
      }
    }
  }
}
