.contact {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  #booker-for-contact {
    button {
      font-size: 15px;
    }
    .dropdown {
      width: 90%;
      button {
        width: 100%;
        font-size: 15px;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .booking-wrapper {
    display: flex; 
    align-items: center; 
    margin: 10px 0;
  }

  .bookings {
    border-bottom: solid 1px $border-color;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .title {
      font-size: 30px;
      font-weight: 700;
      padding: 10px;
      text-align: left;
    }

    .booking-link {
      text-align: left;

      button {
        outline: none;
      }
    }

    .details {
      border-left: 1px solid $border-color; 
      margin-right: 10px;
      padding-left: 10px; 
      margin-left: 35px; 
      font-size: 12px; 
      text-align: left;

      .detail-row {
        display: flex;

        .detail-name {
          min-width: 85px; 
          font-weight: bold;
        }

        .detail-value {
          white-space: nowrap;
        }

      }

    }

  }

  .name {
    border-bottom: solid 1px $border-color;
    font-size: 30px;
    font-weight: 700;
    padding: 10px;
    text-align: left;
  }
}
