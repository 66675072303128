.representative-stats {
  .statistics {
    display: flex;
    flex-direction: row;
    font-size: 12px;
    justify-content: space-between;
/*    padding-left: 45px; */

    @media all and (max-width: 830px) {
      padding-left: 0;
    }

    .statistic {

      .booking-counts {
        max-height: 2.6em;
        overflow-y: auto;
        overflow-x: hidden;
        scrollbar-width: thin;

        .booking-count-row {
          font-weight: normal;
          font-size: .9em;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;

          b {
            margin-right: 0.5rem;
          }  
        }

        &::-webkit-scrollbar {
          background-color: transparent;
          width: 8px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #ccc;
          border-radius: 2px;
        }
        &::-webkit-scrollbar-thumb:hover {
          background: #aaa;
        }
      }

      display: block;
      width: 20%;
      padding: 10px;
      border-right: solid 1px $border-color;
      text-align: left;
      font-size: 17px;
      overflow: hidden;

      @media all and (max-width: $breakpoint-medium) {
        font-size: 14px;
      }

      @media all and (max-width: 750px) {
        font-size: 12px;
        padding: 5px;
      }

      &:nth-of-type(5) {
        border-right: none;
      }

      span {
        display: block;
      }

      span:first-of-type {
        min-width: 175px;
      }

      span:nth-of-type(2) {
        font-weight: 700; // bold
      }
    }
  }
}
