.day {

  min-width: 100px;

  .actions {
    align-items: center;
    border-top: solid 1px $border-color;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 40px;
    justify-content: space-between;

    button {
      border: none;
      height: 100%;
      outline: none;

      &:focus {
        outline: none;
      }
    }

    span {
      text-transform: capitalize;
    }
  }

  .events {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-end;
    padding: 10px;
    text-align: center;

    button {
      width: 50%;
    }
  }

  .heading {
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    font-size: 20px;
    font-weight: 700;
    justify-content: space-between;
    padding: 10px;
    text-align: left;

    .add-button {
      border: none;
      outline: none;
    }
  }

  @media (max-width: 767px) {
    .actions {
      flex-wrap: wrap;
    }

    .events {
      padding: 5px;

      button {
        width: 100%;
      }
    }
  }
}
