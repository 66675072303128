.booker-stats {
  background-color: #ffffff;
  color: #777;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-right: 15px;
  padding: 18px 15px 5px 10px;
  width: 100%;
  border-bottom: solid 1px $border-color;

  .time-frame {
    display: flex;
    flex-direction: row;
    font-size: 11px;
    justify-content: space-between;
    padding: 0px 5px 5px 10px;
    text-transform: uppercase;
  }

  .statistics {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    justify-content: flex-start;

    .statistic {
      border-bottom: solid 1px $border-color;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 5px;
      padding-bottom: 10px;

      &:last-child {
        border-bottom: none !important;
      }
  

      .best-container {
        text-align: right;
        padding: 0px 5px 0px 0px;
        width: 35%;

        .date {
          font-size: 11px;
        }

        .value {
          font-size: 16px;
          font-weight: 700; // bold
        }
      }

      .progress-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: left;

        div {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;

          span {
            font-size: 11px;
            padding: 0px 5px 0px 5px;
            width: 30px;
          }

          .progress {
            height: 8px;
            margin-bottom: 0px;
            margin-top: 4px;
            width: 80px;
          }
        }

        span {
          padding: 0px 10px 0px 20px;
          text-transform: uppercase;

          &.current {
            color: #000;
            font-size: 16px;
            font-weight: 700; // bold
          }
        }
      }
    }
  }
}
