.confirmation-call {
  align-items: center;
  background-color: #ffffff;
  border-bottom: solid 1px $border-color;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 10px;

  .info-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;

    .info-row-column {
      margin-bottom: 10px;
      text-align: left;
      width: 50%;

      .detail {
        margin-top: 3px;
      }

      .title {
        font-size: 18px;
        text-align: left;
        width: 90%;
      }

      button {
        height: 35px;
        margin-right: 10px;
        outline: none;
        width: 120px;

        &:focus {
          outline: none;
        }
      }
    }
  }

  @media (max-width: 767px) {
    border-bottom: none;
    margin-bottom: 10px;
    padding: 10px;

    .info-row {
      flex-wrap: wrap;
      padding: 0px;

      .info-row-column {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        margin-bottom: 0px;
        width: 50%;

        &:nth-child(1),
        &:nth-child(2) {
          margin-bottom: 0px;
          width: 100%;
        }

        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6) {
          margin-top: 10px;
        }

        button {
          width: 120px;
        }

        .detail {
          margin-top: 0px;
        }

        .title {
          font-size: 14px;
          font-weight: 400;
          margin-bottom: 10px;
          padding: 0px;
          text-align: left;
          width: 100%;
        }
      }
    }
  }
}
