.busy-indicator {
  align-items: center;
  color: $bookers-color;
  display: flex;
  font-size: 20px;
  justify-content: center;
  height: 200px;

  .spinner {
    animation: spin 2s linear infinite;
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid $bookers-color;
    height: 100px;
    width: 100px;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
