@charset "UTF-8";

@import './config';

@import url('https://fonts.googleapis.com/css?family=Muli:300,400,700');

@import '../../../node_modules/bootstrap-sass/assets/stylesheets/_bootstrap.scss';

@import './react-tags.scss';

@import 'components/booker-stats';
@import 'components/booking-calendar';
@import 'components/booking-info';
@import 'components/booking-representative';
@import 'components/bottom-bar';
@import 'components/busy-indicator';
@import 'components/call-type';
@import 'components/confirmation-call';
@import 'components/contact-header';
@import 'components/contact-info';
@import 'components/contact-status';
@import 'components/date-picker';
@import 'components/day';
@import 'components/error-banner';
@import 'components/event-button';
@import 'components/representative-info';
@import 'components/representative-info-reschedule';
@import 'components/representative-stats';
@import 'components/reservation';
@import 'components/search';
@import 'components/suggestion';
@import 'components/time-picker';
@import 'components/top-bar';
@import 'components/week';
@import 'components/cookie-consent';

@import 'views/booking-booker';
@import 'views/call';
@import 'views/contact';
@import 'views/contacts';
@import 'views/contacts-new';
@import 'views/copy-booking';
@import 'views/edit-event';
@import 'views/event';
@import 'views/login';
@import 'views/not-found';
@import 'views/reports';
@import 'views/representative';
@import 'views/suggestions';
@import 'views/proposed';
@import 'views/project';
@import 'views/project-import';
@import 'views/project-campaigns';

body,
html {
  background-color: #f2f2f2;
  color: #333333;
  font-family: Muli, Arial, Helvetica, sans-serif;
  height: 100%;
  margin: 0;

  .modal-content {
    top: 220px;

    button {
      width: 120px;
    }
  }

  .rep-info-row {
    .dropdown-menu {
      min-width: 100%;
      max-height: 50vh;
      overflow-y: auto;
    }
  }

  .contacts-to-remove .table-responsive {
    max-height: 80vh;
    td {
      white-space: nowrap;
    }
  }

  .full-width-table-responsive {
    & > .table-responsive {
      width: 100%;
    }
  }

  .row .side-bar {
    display: flex;
    flex-flow: column;
  }

  .row {
    > .section:last-child, > .summary:last-child {
      border-bottom: 0;
    }
  }

  .wrapper {
    background-color: white;

    &.container:not(.week-wrapper), .rbc-time-view {
      border: 0 !important;
      border-radius: 10px;
      box-shadow: 1px 1px 9px rgba(0,0,0,.1);
    }

    margin-top: 10px;
    margin-bottom: 10px;

    /* used to be auto */
    overflow-y: visible;

    @media (max-width: 767px) {
      border: none;
      border-radius: 0px;
      height: 100vh;
      margin-top: 0px;
    }
  }

  .btn-link {
    color: $bookers-color;

    &:hover,
    &:focus {
      color: darken($bookers-color, 15%);
    }
  }

  .react-tags__search-input {
    width: 2px !important;
  }
  .clients .react-tags__search-input {
    width: auto !important;
  }
  .clients .react-tags {
    display: flex;
    flex-flow: row;
    .react-tags__selected {
      display: flex;
    }
  }

  .no-tags .react-tags__search-input {
    width: 150px !important;
  }

  .elevated {
    box-shadow: 1px 1px 2px #444;
    position: relative;
    top: -1px;
    left: -1px;
  }

  .third-of-opacity {
    opacity: .33;
  }

  .contact-info-tags {
    span, div {
      font-weight: normal !important;
    }
    button.react-tags__selected-tag {
      width: auto !important;
    }
  }

  .campaign-tags {
    display: flex;
    .clear {
      margin-left: 12px; 
      display: flex; 
      align-items: center;
      cursor: pointer;
      opacity: .5;
      &:hover {
        opacity: 1;
      }
    }
    .react-tags {
      z-index: 60;
    }
    .react-tags__search {
      border-bottom: 1px solid #ccc;
    }
    .react-tags__search-input {
      width: 100% !important;
    }
  }

  .business-5 .react-tags {
    z-index: 50;
  }
  .business-4 .react-tags {
    z-index: 40;
  }
  .business-3 .react-tags {
    z-index: 30;
  }
  .business-2 .react-tags {
    z-index: 20;
  }
  .business-1 .react-tags {
    z-index: 10;
  }

  .business-export {
    &.additional-info-filter {
      .react-tags {
        display: flex;
      }
      .react-tags__search {
        width: 100%;
      }
    }
    .react-tags__search {
      border-bottom: 1px solid #ccc;
    }
    .react-tags__search-input {
      width: 100% !important;
    }
  }

  .range-container {
    span {
      font-size: 10px;
      color: #333333;
      font-family: Muli, Arial, Helvetica, sans-serif;
    }
    .input-range {
      height: 38px;
    }
    .input-range__slider {
      top: -4px;
      height: 10px;
      width: 1.3rem;
      height: 1.3rem;
    }
    .input-range__label--min, .input-range__label--max {
      span {
        display: none;
      }
    }
    .input-range__label-container {
      top: -8px;
    }
    .input-range__track {
      height: 8px;
    }
  }

  .project-settings {

    .booker-stat-range {
      display: flex;
      max-width: 300px;
      min-width: 180px;
      width: 80%;
      & > div:first-of-type {
        margin-right: 5px;  
      }
      & > div {
        display: flex;
        position: relative;
        align-items: center;
      }
      .color-tag {
        position: absolute;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-left: 8px;
        &.red {
          background-color: #d9534f;
        }
        &.yellow {
          background-color: #f0ad4e;
        }
        &.green {
          background-color: #5cb85c;
        }
      }
    }

  }

  @keyframes top-cricle {
    from {
      transform: rotate(-25deg);
    }
    to {
      transform: rotate(335deg);
    }
  }
  @keyframes bottom-cricle {
    from {
      transform: rotate(-15deg);
    }
    to {
      transform: rotate(345deg);
    }
  }
  .spinner-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 30px;
    .spinner {
      padding-right: 35px;
      & > span {
        display: inline-block;
        position: absolute;
        border-radius: 100px;
        padding: 8px;
        border: 5px solid transparent;
        &.top {
          border-top: 5px solid white;
          animation: top-cricle 1s ease-in-out infinite;
          animation-delay: 0.5s;
        }
        &.bottom {
          border-top: 5px solid #49c231;
          animation: bottom-cricle 1s ease-in-out infinite;
        }
      }
    }
  }

  .api-access-text-name {
    width: 100% !important;
  }

  .scope-column {
    width: 230px;
    .dropdown {
      width: 100%;
      button {
        width: 100%;
        text-align: left;
      }
      .dropdown-menu {
        width: 100%;
      }
    }
  }

  .outlook-status-modal {
    ul[role="menu"] {
      max-height: 150px;
      overflow-y: auto;
    }
  }

}

@keyframes pulsate {
  0% { transform: scale(.9); }
  50% { transform: scale(1.2); }
  100% { transform: scale(.9); }
}

.ui-notification {
  animation-timing-function: ease-in-out;
  animation: pulsate 2s infinite;
  position: absolute;
  border-radius: 50%;
  background-color: #a51890;
  border: 2px solid white;
}

.outlook-status-modal {
  .modal-content {
    .alert-danger {
      button {
        width: initial;
      }  
    }
  }
}