.time-picker {
  border: none;
  border-radius: 10px;
  cursor: default;
  padding: 10px;

  &:focus {
    background-color: #fff;
    color: #000;
    outline: none;
    padding-right: rem(5px);
  }
}
