.contact-status {
  border-bottom: solid 1px $border-color;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 10px 10px 20px 10px;

  &.wrap {
    flex-wrap: wrap;
  }

  .free-time-list {
    color: black;
    padding: 5px 0;
    border: 1px solid #ccc;
    width: 100%; 
    max-height: 320px; 
    min-width: 150px;
    background-color: white; 
    position: absolute;
    z-index: 100;
    border-radius: 4px;
    margin-top: 1px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    overflow-y: auto;
    .item {
      cursor: pointer;
      padding: 3px 20px;
      &:hover {
        background-color: #f5f5f5;
      }
    }
  }  

  .status-column {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    text-align: left;
    width: 25%;

    &.status-column-wide {
      &.status-column-wide-single {
        .dropdown {
          width: 100%;
        }
      }
      .dropdown {
        ul {
          max-height: 480px;
          overflow-y: auto;
          width: 100%;
          min-width: 100%;
        }
      }
      flex: 1;
    }

    &.save {
      justify-content: flex-end;
    }

    .date-picker-container {
      border: solid 1px $border-color;
      border-radius: 4px;
      height: 40px;
      width: 60%;

      .date-picker {
        width: 100%;
      }
    }

    .dropdown {
      height: 35px;
      width: 90%;
    }

    .heading {
      font-size: 20px;
      font-weight: 700;
      padding-bottom: 10px;
      text-align: left;
    }

    .picker-row {
      align-items: center;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;

      &.booking {
        .date-picker-container {
          width: 75%;
        }
        .time-picker {
          width: 75% !important;
        }
      }

      span {
        width: 15%;
      }
    }

    button {
      font-size: 15px;
      height: 100%;
      overflow: hidden;
      text-align: left;
      text-overflow: ellipsis;
      width: 100%;

      &.dropdown-toggle {
        height: 100%;
      }

      &.save {
        height: 35px;
        margin-right: 10px;
        min-width: 135px;
        outline: none;
        text-align: center;
        width: 14%;

        &:focus {
          outline: none;
        }
      }
    }

    input[type='time'] {
      border: solid 1px $border-color;
      border-radius: 4px;
      height: 40px;
      margin-top: 5px;
      outline: none;
      width: 60%;
    }

    textarea {
      border: solid 1px $border-color;
      border-radius: 4px;
      font-size: 13px;
      height: 85px;
      outline: none;
      padding: 5px;
      resize: none;
      width: 90%;
    }
  }

  @media (max-width: 767px) {
    .status-row {
      border-bottom: solid 1px $border-color;
      flex-wrap: wrap;

      div {
        flex-basis: 50%;
      }

      .status-row-column {
        // When sub status is visible, save button will likely to
        // be wrapped to second row
        button:last-child {
          margin-top: 10px;
        }

        input[type='time'] {
          width: 100%;
        }

        .date-picker-container {
          width: 100%;
        }
      }
    }
  }
}
