.booking-booker {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .booking-actions {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    padding: 10px;

    button {
      outline: none;
      margin-right: 10px;
      min-width: 120px;

      &.btn-link {
        padding: 6px;
        min-width: 100px;
        width: auto;
      }
    }
  }

  .info-row {
    border-bottom: solid 1px $border-color;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 10px;

    &.wrap {
      flex-wrap: wrap;
    }

    .info-row-column {
      text-align: left;
      width: 25%;

      button {
        font-size: 15px;
        height: 100%;
        text-align: left;
        width: 100%;

        &.dropdown-toggle {
          padding: 5px;
          height: 100%;
        }
      }

      span {
        font-weight: 700; // bold
      }

      .dropdown {
        width: 90%;
      }

      &.double {
        width: 50%;
      }

      &.date-picker-container {
        width: 90%;

        .date-picker {
          width: 100%;
        }
      }

      .heading {
        font-weight: 700; // bold
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      textarea {
        border: solid 1px $border-color;
        border-radius: 4px;
        font-size: 13px;
        height: 75px;
        outline: none;
        padding: 5px;
        resize: none;
        width: 95%;
      }
    }

    &.notes {
      border-bottom: none;
    }
  }

  @media (max-width: 767px) {
    .info-row {
      flex-wrap: wrap;

      .info-row-column {
        width: 50%;

        &.double {
          width: 100%;
        }

        .heading {
          height: 22px;
        }

        textarea {
          width: 100%;
        }

        &:nth-child(3),
        &:nth-child(4) {
          margin-top: 10px;
        }
      }
    }
  }
}
