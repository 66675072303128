.additional-info-filter {
  .dropdown.btn-group {
    width: 100%;
  }
}

.decision-maker-card {
  .react-tags {
    margin-bottom: 6px;
  }
  .react-tags__search {
    padding-top: 3px;
    padding-bottom: 6px;
    margin-bottom: 0;
  }
  .decision-maker-field-title {
    font-size: 0.8em;
    position: relative;
  }
}

.decision-maker-card-more {
  display: inline-block !important;
  margin-left: 5px;
  & > ul {
    &, & > li, & > li > a, & > li > a > div, & > li > a > div > span {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 25px;
      height: 25px;
      color: black;
      padding: 0 !important;
    }
  }
}

.decision-maker-info-ext {
  [role=button] {
    white-space: nowrap;
  }
  .busy-indicator {
    padding-top: 8px;
    height: 75px;
    margin: auto;
    .spinner {
      height: 50px;
      width: 50px;
      border-width: 8px;
    }
  }
}

.call {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .content {
    width: 100%;
  }

  .side-bar {
    border-right: solid 1px $border-color;
    max-width: 275px;
  }

  @media (max-width: 767px) {
    .side-bar {
      display: none;
    }
  }

}

.noshow-filters {

  .content {

    .status-section {
      .dropdown {
        width: 300px !important;
      }
    }


    .navigation {
      button {
        padding-top: 10px !important;
        padding-bottom: 10px;
      }
    }
  }

}
