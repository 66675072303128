.error-banner {
  .modal-body {
    font-family: Muli;
    font-size: 13px;
    overflow: hidden;
    white-space: pre-wrap;
  }

  .modal-content {
    border: none;
  }

  .modal-footer {
    text-align: center;

    button {
      background-color: #b94a48;
      border-radius: 4px;
      color: #ffffff;
      outline: none;

      &:focus {
        outline: none;
      }
    }
  }

  .modal-header {
    background-color: #b94a48;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    color: #ffffff;
    height: 100px;
    text-align: center;

    .modal-title {
      padding-top: 10px;

      span {
        font-size: 50px;
      }
    }
  }
}
