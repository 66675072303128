.wrapper.week-wrapper {
  @media (min-width: 667px) {
    background-color: transparent;
    border: none;
    margin-top: 0;
    margin-bottom: 0;
  }

  .sok-event {
    display: inline-block; 
    width: 8px;
    height: 8px; 
    border-radius: 50%; 
    border: 1px solid white;
    background-color: rgb(0, 170, 70);
    margin-right: 3px;
  }
}

.week {
  .rbc-calendar {
    .rbc-toolbar {
      overflow: hidden;
      margin: 0.6em 0;
      padding: 10px 10px 10px 45px;

      @media (max-width: $breakpoint-medium) {
        margin: 0.6em 0;
      }

      .rbc-btn-group {
        button {
          width: 100px;
          margin-right: 10px;
          border: solid 2px darken($border-color, 7%);
          border-radius: 0;
          outline: none;
          color: #878787;
          font-size: 14px;
          font-weight: 700;
          text-transform: capitalize;
          transition: border-color 0.05s ease, background-color 0.025s ease, color 0.05s ease;

          &:focus {
            background-color: transparent;
          }

          &:hover {
            border-color: $bookers-color;
            background-color: $bookers-color;
            color: white;
          }

        }
      }

      .rbc-toolbar-label {
        color: $bookers-color;
        font-size: 24px;
        font-weight: 700; // bold
        text-align: right;
        text-transform: capitalize;

        @media (max-width: $breakpoint-medium) {
          font-size: 20px;
        }
      }
    }

    .rbc-time-view {
      border: 1px solid $border-color;
      border-radius: 10px;
      background-color: #ffffff;
      overflow: hidden;

      .rbc-time-header {
        .rbc-header {
          padding: 6px 0;
          font-size: 14px;
          font-weight: 700;
          text-transform: capitalize;
        }

        .rbc-header-gutter {
          border-right: solid 1px $border-color;
          width: 36px;
        }


        div.rbc-row-segment {
          max-width: fit-content;

          div.rbc-event.rbc-event-allday {
            padding: 0px 0px;
            border-radius: 0px;

            div.rbc-event-content-wrap {

              &.note, &.block {
                padding: 2px 5px;
                background-color: rgb(255, 255, 255);
                font-weight: bold;
                cursor: default;

                .rbc-event-title {
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }

              &.note {
                color:rgb(255, 0, 0);
              }

              &.block {
                color:rgb(0, 0, 0);
              }
            }
            
          }
        }

      }

      .rbc-row {
        > * + * {
          border-left: none !important;
        }
        .rbc-header {
          font-size: 14px;
          padding-top: 3px;
          border-bottom: 1px solid $border-color;
        }
        .rbc-today {
          &.rbc-header {
            background-color: $bookers-color;
            color: #fff;
          }
        }
      }

      .rbc-allday-cell {
        border-bottom: 1px solid $border-color;

        .rbc-row-bg {
          .rbc-day-bg.rbc-today {
            background-color: #ffffff;
          }
        }
      }
    }

    .rbc-time-content {
      border-top: none;
      font-size: 13px;

      .rbc-current-time-indicator {
        background-color: $bookers-color;

        &::before {
          background-color: $bookers-color;
        }
      }

      .rbc-time-gutter {
        border: none;
        text-align: right;
        width: 35px !important;

        .rbc-timeslot-group {
          border: none;

          .rbc-time-slot {
            padding: 0 6px;
            font-size: 12px;
            font-weight: 700;
            color: rgba(0, 0, 0, 0.3);

            &.rbc-now {
              color: rgba(0, 0, 0, 0.7);
            }
          }
        }
      }

      .rbc-day-slot {
        &.rbc-today {
          background-color: #ffffff;
        }

        .rbc-events-container {
          right: 0;

          .rbc-event {
            $event-border-width: 2px;

            padding: 0;
            border: $event-border-width solid white;
            border-radius: 0;
            color: #000;
            background-color: #fff;

            &.free {
              background-color: $event-bg-color-free;
              color: $event-color-free;

              .rbc-event-icon {
                background-color: $event-bg-color-free;
              }
            }

            &.notConfirmed {
              border: $event-border-width dashed $event-bg-color-not-confirmed;
              color: $event-color-not-confirmed;

              .rbc-event-icon {
                background-color: white;
              }
            }

            &.created {
              border-color: $event-bg-color-created;
              color: $event-color-created;

              .rbc-event-icon {
                background-color: white;
              }
            }

            &.realisedOutcomeUnmarked {
              border-color: $event-color-created;
              color: white;
              background-color: $event-color-created;

              .rbc-event-icon {
                background-color: white;
              }
            }

            &.outcomeUnmarked {
              border-color: $event-bg-color-outcome-unmarked;
              color: $event-color-outcome-unmarked;

              .rbc-event-icon {
                background-color: white;
              }
            }

            &.rescheduled {
              border-color: $event-bg-color-rescheduled;
              color: $event-color-rescheduled;

              .rbc-event-icon {
                background-color: white;
              }
            }

            &.deal {
              background-color: $event-bg-color-deal;
              color: $event-color-deal;

              .rbc-event-content-wrap:not(.small) {
                border-top: $event-border-width solid $event-color-deal;
              }

              .rbc-event-icon {
                background-color: $event-bg-color-deal;
              }
            }

            &.noDeal {
              background-color: $event-bg-color-no-deal;
              color: $event-color-no-deal;

              .rbc-event-content-wrap:not(.small) {
                border-top: $event-border-width solid $event-color-no-deal;
              }

              .rbc-event-icon {
                background-color: $event-bg-color-no-deal;
              }
            }

            &.dealProposed {
              background-color: $event-bg-color-deal-proposed;
              color: $event-color-deal-proposed;

              .rbc-event-icon {
                background-color: $event-bg-color-deal-proposed;
                opacity: 0.5;
              }
            }

            &.followUp {
              background-color: $event-bg-color-follow-up;
              color: $event-color-follow-up;

              .rbc-event-icon {
                background-color: $event-bg-color-follow-up;
              }
            }

            &.tentative {
              border-color: $event-bg-color-tentative;
              color: $event-color-tentative;

              .rbc-event-icon {
                background-color: white;
              }
            }

            &.reserved {
              background-color: $event-bg-color-reserved;
              color: $event-color-reserved;

              .rbc-event-icon {
                background-color: $event-bg-color-reserved;
              }
            }

            &.noShow {
              border-color: $event-bg-color-no-show;
              color: $event-color-no-show;

              .rbc-event-icon {
                background-color: white;
              }
            }

            &.personal {
              background-color: $event-bg-color-personal;
              color: $event-color-personal;

              .rbc-event-icon {
                background-color: $event-bg-color-personal;
              }
            }

            &.external {
              background-color: $event-bg-color-external;
              color: $event-color-external;
              cursor: not-allowed !important;

              .rbc-event-icon {
                background-color: $event-bg-color-external;
              }
            }

            &.undefined {
              background-color: #e9e9e9;
            }

            .rbc-event-label {
              display: none;
            }

            .rbc-event-content {
              .rbc-event-content-wrap {
                position: relative;
                display: flex;
                flex-flow: column wrap;
                align-content: flex-start;
                height: 100%;
                padding-right: 8px;
                padding-bottom: 0;
                font-size: 12px;
                text-align: left;

                .rbc-event-type-indicator{
                  height: 100%;
                  width: 6px;
                  margin-right: 2px;

                  &.call{
                    background-color: $event-type-color-call;
                  }

                  &.remote{
                    background-color: $event-type-color-remote;
                  }

                  &.servicePoint{
                    background-color: $event-type-color-servicePoint;
                  }

                  &.online{
                    background-color: $event-type-color-online;
                  }
                }

                &.small {
                  .rbc-event-time {
                    max-width: '100%';
                    overflow: 'hidden';
                    text-overflow: 'ellipsis'; 
                    font-size: 11px;
                    padding-top: 2px;
                  }

                  .rbc-event-title {
                    font-size: 11px;
                  }

                  .rbc-event-title {
                    margin-top: 1px;
                  }

                  .rbc-event-icon {
                    top: 0;
                    right: 0;
                    width: 21px;
                    height: 100%;
                    padding-left: 4px;
                    padding-right: 8px;
                  }
                }

                .rbc-event-time {
                  padding-top: 2px;
                  margin: 1px 8px 2px 0;
                  white-space: nowrap;
                }

                .rbc-event-title {
                  flex: 1 1;
                  height: 100%;
                  overflow-y: hidden;
                  margin-right: 16px;
                  font-weight: 700;
                  font-size: 13px;

                  @media (max-width: $breakpoint-medium) {
                    font-size: 12px;
                  }
                }

                .rbc-event-icon {
                  position: absolute;
                  width: 12px;
                  top: 12px;
                  right: 8px;
                }
              }
            }
          }
        }
      }
    }
  }
}

