.reports {
/*  min-height: 360px;*/

  .reset-expansion-style {

    background-color: #f8f8f8;

    &>div {
      border: 1px solid #ddd;
    }

    table {
      margin-bottom: 0;
      td {
        vertical-align: middle;
      }
    }

  }

  .react-bs-table td {
    white-space: normal;
  }

  .report-select {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin: 15px 15px 0 15px;
    text-align: left;
    width: 100%;

    .heading {
      font-size: 30px;
      font-weight: bold;
      margin: 0 30px 15px 0;
    }

    .dropdown {
      margin-bottom: 15px;

      button {
        min-width: 120px;
      }

      span {
        margin-left: 10px;
      }

      .dropdown-menu {
        width: inherit;
      }
    }
  }

  .search {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
    width: 100%;

    .time-filters {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      margin: 0 0 10px 15px;

      .date-picker {
        border: solid 1px $border-color;
        border-radius: 5px;
        width: 120px;
      }

      span {
        margin: 0 10px 0 10px;
      }
    }

    button {
      margin: 0 0 10px 15px;
      min-width: 120px;
    }
  }

  .id-filters {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 0 10px 15px;

    .id-filter {
      margin: 0 5px 5px 0;

      button {
        min-width: 170px;
      }
    }
  }

  .report {
    margin: 15px 0 15px 0;
    padding: 10px;

    table {
      td {
        font-size: 14px;
        outline: none;
      }

      th {
        border: none;
        font-size: 13px;
        font-weight: normal;
        white-space: pre-wrap;
      }
    }
  }
}
