.event-button {
  $border-width: 1px;
  $border-color-darken: 20%;
  $border-color-darken-alt: 40%;

  .event-type-indicator{
    position: absolute;
    height: 100%;
    width: 8px;
    margin-left: -12px;
    margin-top: -6px;
  
    &.call{
      background-color: $event-type-color-call;
    }
  
    &.remote{
      background-color: $event-type-color-remote;
    }
  
    &.servicePoint{
      background-color: $event-type-color-servicePoint;
    }
  
    &.online{
      background-color: $event-type-color-online;
    }
  }

  .btn {
    position: relative;
    width: 100%;
    height: 50px;
    margin-bottom: 10px;
    border: $border-width solid $border-color;
    border-radius: 0;
    background-color: #ffffff;
    transition: opacity 0.05s;

    @media (max-width: 767px) {
      margin-bottom: 5px;
    }

    &:focus {
      opacity: 0.7;
    }

    &:active,
    &:focus,
    &:active:focus,
    &.active:focus {
      outline: none;
    }

    $narrow: 400px;

    .event-time-title {
      text-align: left;
      margin-left: 160px;
      @media (max-width: $narrow) {
        margin-left: 95px;
      }
    }

    .time {
      font-weight: 700;
    }

    .title {
      overflow-x: hidden;
      margin: 0 16px;
    }

    .call-sms-icon {
      font-size: 20px;
      position: absolute;
      width: 70px;
      @media (max-width: $narrow) {
        width: 40px;
      }
    }

    .event-icon {
      font-size: 20px;
      position: absolute;
      right: 10px;
      top: 10px;

      svg {
        width: 12px;
      }
    }

  }

  &.free > .btn {
    border-color: darken($event-bg-color-free, $border-color-darken);
    background-color: $event-bg-color-free;
    color: $event-color-free;
  }

  &.notConfirmed > .btn {
    border: $border-width dashed $event-bg-color-not-confirmed;
    color: $event-color-not-confirmed;
  }

  &.created > .btn {
    border-color: $event-bg-color-created;
    color: $event-color-created;
  }

  &.outcomeUnmarked > .btn {
    border-color: $event-color-outcome-unmarked;
    color: $event-color-outcome-unmarked;
  }

  &.rescheduled > .btn {
    border-color: $event-color-rescheduled;
    color: $event-color-rescheduled;
  }

  &.deal {
    .btn {
      border-left: 0;
      border-color: darken($event-bg-color-deal, $border-color-darken-alt);
      background-color: $event-bg-color-deal;
      color: $event-color-deal;

      &:before {
        content: '';
        position: absolute;
        display: block;
        height: 50px;
        left: 0;
        padding: 0px 0;
        top: -1px;
        bottom: -1px;
      }
    }
  }

  &.noDeal {
    .btn {
      border-left: 0;
      border-color: darken($event-bg-color-no-deal, $border-color-darken-alt);
      background-color: $event-bg-color-no-deal;
      color: $event-color-no-deal;

      &:before {
        content: '';
        position: absolute;
        display: block;
        height: 50px;
        left: 0;
        padding: 0px 0;
        top: -1px;
        bottom: -1px;
      }
    }
  }

  &.dealProposed > .btn {
    border-color: darken($event-bg-color-deal-proposed, $border-color-darken-alt);
    background-color: $event-bg-color-deal-proposed;
    color: $event-color-deal-proposed;

    .event-icon {
      opacity: 0.5;
    }
  }

  &.followUp > .btn {
    border-color: darken($event-bg-color-follow-up, $border-color-darken-alt);
    background-color: $event-bg-color-follow-up;
    color: $event-color-follow-up;
  }

  &.realisedOutcomeUnmarked > .btn {
    border-color: darken($event-color-created, $border-color-darken-alt);
    background-color: $event-color-created;
    color: white;
  }

  &.tentative > .btn {
    border-color: darken($event-bg-color-tentative, $border-color-darken);
    color: $event-color-tentative;
  }

  &.reserved > .btn {
    border-color: darken($event-bg-color-reserved, $border-color-darken);
    background-color: $event-bg-color-reserved;
    color: $event-color-reserved;
  }

  &.noShow > .btn {
    border-color: darken($event-bg-color-no-show, $border-color-darken);
    background-color: $event-bg-color-no-show;
    color: $event-color-no-show;
  }

  &.personal > .btn {
    border-color: darken($event-bg-color-personal, $border-color-darken);
    background-color: $event-bg-color-personal;
    color: $event-color-personal;
  }

  &.external > .btn {
    border-color: darken($event-bg-color-external, $border-color-darken);
    background-color: $event-bg-color-external;
    color: $event-color-external;
  }

  &.undefined > .btn {
    background-color: #e9e9e9;
  }
}
