.suggestion {

  &.active, &.reserved {
    .btn {
      background-color: #5cb85c;
      border: solid 1px #4cae4c;
      color: #ffffff;
    }
  }

  .btn {
    background-color: #ffffff;
    border: solid 1px $border-color;
    height: 75px;
    margin-bottom: 10px;
    width: 100%;

    &:hover {
      border: solid 1px #4cae4c;
    }

    .day-and-date {
      font-size: 13px;
      text-transform: capitalize;
    }

    .representative {
      font-size: 13px;
    }

    .time {
      font-weight: 700;
    }

    .time-left {
      font-size: 13px;
    }
  }

  .btn:active, .btn:focus, .btn:active:focus, .btn.active:focus {
    outline: none;
  }

  @media (max-width: 767px) {

    .btn {
      font-size: 12px;
    }
  }
}
