.contacts {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  text-align: left;

  .heading {
    border-bottom: solid 1px $border-color;
    font-size: 24px;
    font-weight: 700; // bold
    padding: 20px 20px 20px 20px;
    text-align: left;
  }

  .subheading {
    font-size: 16px;
    padding: 10px 20px 10px 20px;
    text-align: left;
  }

  .selections {
    margin-bottom: 0px;
    padding: 20px 0px 0px 5px;
    text-align: left;
    width: 100%;

    .selection {
      button {
        background: none;
        border: none;
        outline: none;

        &:hover {
          color: red;
        }

        span {
          margin-left: 10px;
        }
      }
    }
  }

  .no-selections {
    padding: 20px 0px 0px 10px;
    text-align: left;
    width: 100%;
  }

  .section {
    border-bottom: solid 1px $border-color;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
    padding: 0px 20px 20px 20px;

    .dropdown {
      margin-right: 10px;
      width: 200px;

      button {
        border-color: $border-color;
        border-radius: 4px;
        height: 35px;
        text-align: left;
        width: 100%;
      }

      ul {
        border-color: $border-color;
        max-height: 285px;
        overflow-y: auto;
        min-width: 100%;
      }

      @media (max-width: 767px) {
        margin-bottom: 5px;
      }
    }

    #select-postal-areas-container {
      .dropdown {
        width: 250px;
      }
    }


  }

  .summary {
    border-bottom: solid 1px $border-color;
    display: flex;
    flex-direction: column;
    font-size: 24px;
    font-weight: 700; // bold
    padding: 20px;
    text-align: left;

    button {
      background-color: $bookers-color;
      border: none;
      font-size: 18px;
      height: 50px;
      margin: 20px 0 20px 0;
      outline: none;
      width: 150px;

      &.focus {
        outline: none;
      }
    }
  }

  @media (max-width: 767px) {
    border: none;
  }
}
