.call-type {
  border-bottom: solid 1px $border-color;
  height: 162px;
  padding: 18px 15px 0px 10px;

  &.with-confirmation {
    height: 206px;
  }

  .dropdown {
    padding-bottom: 10px;
    width: 100%;

    button {
      color: #777;
      height: 35px;
      outline: none;
      text-align: left;
      width: 85%;

      &.current {
        background-color: $bookers-color;
        color: #fff;
      }

      &.dropdown-toggle {
        padding-right: 10px;
/*        padding-top: 0px;*/
        padding-top: 6px;
        width: 15%;
        text-align: center;
      }
    }

    ul {
      margin-top: -8px;
      right: 137px;
      width: 100%;
      padding-top: 0;
      padding-bottom: 0;
      max-height: 50vh;
      overflow-x: hidden;
      overflow-y: auto;    

      li:not(:last-child) {
        border-bottom: solid 1px $border-color;
      }

      li {
        height: 45px;
        
        &.filter {
          padding: 0 !important;
          height: 100%;

          a {
            padding-top: 8px;
            padding-bottom: 8px;
          }  
        
        }

        a {

          color: #777;
          padding-left: 10px;
          white-space: normal;

          .glyphicon {
            padding-right: 10px;
          }

          .date {
            font-size: 13px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
      }
    }
  }
}
