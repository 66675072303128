.bottom-bar {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 400;
  height: 40px;
  justify-content: space-between;

  @media (max-width: 767px) {
    display: none;
  }

  .content {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .debug-buttons {
      button {
        background: none;
        border: none;
        margin-right: 10px;
        outline: none;
      }
    }

    .socket-indicator {
      padding-right: 25px;

      &.open {
        color: green;
      }

      &.connecting,
      &.disconnecting {
        color: orange;
      }

      &.closed {
        color: red;
      }
    }
  }
}
