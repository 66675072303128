.contact-header {
  align-items: center;
  border-bottom: solid 1px $border-color;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 10px;

  .btn {
    height: 35px;
    margin-top: 5px;
    outline: none;
    width: 120px;

    &:focus {
      outline: none;
    }

    &.btn-link {
      padding: 6px;
      width: auto;
    }
  }

  div {
    padding: 5px;

    &:first-of-type {
      text-align: left;
      width: 100%;
    }
  }

  .name {
    font-size: 30px;
    font-weight: 700;
    padding-left: 0px;
  }

  @media (max-width: 767px) {
    border-bottom: solid 1px $border-color;
    flex-wrap: wrap;
  }
}
