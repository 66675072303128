.proposed.container {
    .wrapper {
        border-radius: 0;
        height: auto;
    }
    
    .table {
        margin-bottom: 0;

        td.nowrap {
            white-space: nowrap;
        }

        tbody tr:hover {
            cursor: pointer;
            background-color: #f8f8f8
        }
    }
}