.booking-info {
  background-color: #ffffff;

  .details {
    border-bottom: solid 1px $border-color;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: left;

    .short-note {
      padding: 0 0 10px 0;
      text-align: left;
      flex: 1;
      min-width: 50%;

      input {        
        padding: 5px !important;
        border: solid 1px $border-color;
        border-radius: 4px;
        padding: 5px 5px 5px 0px;
        width: 100%;
      }
    }

    span {
      font-weight: 700;
    }

    .status {
      padding: 5px 10px 5px 10px;
      text-align: left;
      flex: 1;
      min-width: 50%;

      .status {
        padding-left: 0;
      }

      .dropdown {
        border: solid 1px $border-color;
        border-radius: 4px;
        padding: 5px 5px 5px 0px;
        width: 100%;

        button {
          border: none;
          text-align: left;
          width: 100%;
        }

        .dropdown-menu {
          width: 100%;
        }
      }
    }

  }

  hr {
    border: none;
    border-top: solid 1px $border-color;
    margin: 0px;
  }

  .info-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 10px;

    &.wrap {
      flex-wrap: wrap;
    }

    .info-row-column {
//      padding-bottom: 10px;
      text-align: left;
      width: 25%;

      .dropdown {
        width: 90%;
      }

      button {
        font-size: 15px;
        height: 100%;
        text-align: left;
        width: 100%;

        &.dropdown-toggle {
          padding: 5px;
          height: 100%;
        }
      }

      span {
        font-weight: 700; // bold
      }

      &.date-picker-container {
        width: 90%;

        .date-picker {
          width: 100%;
        }
      }

      input[type='text'] {
        border: none;
        outline: none;
        width: 90%;

        &::placeholder {
          color: #333333;
        }
      }

      textarea {
        border: solid 1px $border-color;
        border-radius: 4px;
        height: 75%;
        outline: none;
        resize: none;
        width: 90%;
      }
    }
  }

  .heading {
    font-size: 20px;
    font-weight: 700;
    padding: 10px 0 10px 10px;
    text-align: left;

    button {
      border: none;
      height: 25px;
      margin: 0px 0px 5px 10px;
      outline: none;

      &:focus {
        outline: none;
      }
    }
  }

  @media (max-width: 767px) {
    hr {
      border: none;
      margin: 0px;
    }
    .heading {
      font-size: 20px;
      font-weight: 400; // normal
      margin-bottom: 0px;
      padding-bottom: 10px;
    }

    .info-row {
      background-color: #ffffff;
      border-bottom: none;
      flex-wrap: wrap;
      margin-bottom: 10px;
      padding: 0px 10px 10px 10px;

      div {
        flex-basis: 100%;
      }
    }
  }
}
