.booking-representative {
  .created {
    border-top: solid 1px $border-color;
    font-size: 13px;
    padding: 10px;
    text-align: left;
  }

  .details {
    border-bottom: solid 1px $border-color;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: left;

    .short-note {
      padding: 0 10px 5px 0;
      text-align: left;
      flex: 1;
      min-width: 50%;

      input {        
        padding: 5px !important;
        border: solid 1px $border-color;
        border-radius: 4px;
        padding: 5px 5px 5px 0px;
        width: 100%;
      }
    }

    span {
      font-weight: 700; // bold
    }

    textarea {
      border: solid 1px $border-color;
      border-radius: 4px;
      font-size: 13px;
      height: 85px;
      outline: none;
      padding: 5px;
      resize: none;
      width: 100%;
    }

    .notes {
      padding: 10px;
      flex: 1;
      min-width: 50%;
      display: flex;
      flex-flow: column;

      textarea {
        flex: 1;
        min-height: 85px;
      }

    }

    .status {
      padding: 5px 10px 5px 10px;
      text-align: left;
      flex: 1;
      min-width: 50%;

      .status {
        padding-left: 0;
      }

      .dropdown {
        border: solid 1px $border-color;
        border-radius: 4px;
        padding: 5px 5px 5px 0px;
        width: 100%;

        li > a {
          overflow: hidden;
          text-overflow: ellipsis;
        }

        button {
          border: none;
          text-align: left;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;      
        }

        .dropdown-menu {
          width: 100%;
        }
      }
    }
  }

  .header {
    border-bottom: solid 1px $border-color;
    font-weight: 400; // normal
    text-align: left;
    font-size: 20px;

    .info {
      font-size: 14px;
      font-weight: 400; // normal
      padding: 10px;
      text-transform: capitalize;

      &:nth-of-type(3) {
        padding-top: 0px;
      }

      &.border {
        border-bottom: solid 1px $border-color;
        padding-bottom: 10px;
      }

      
      .event-type-dot {
        height: 8px;
        width: 8px;
        border-radius: 50%;
        background-color: rgb(0, 0, 0);
        display: inline-block;

        &.call{
          background-color: $event-type-color-call;
        }

        &.remote{
          background-color: $event-type-color-remote;
        }

        &.servicePoint{
          background-color: $event-type-color-servicePoint;
        }

        &.online{
          background-color: $event-type-color-online;
        }
      }

    }

    .title {
      border-bottom: solid 1px $border-color;
      padding: 10px;
    }
  }

  .navigation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    button {
      outline: none;
    }

    .actions {
      padding: 6px 0;
      display: flex;
      align-items: center;

      button {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }

  @media (max-width: 640px) {
    .details {
      flex-flow: column;
    }
  }

  @media (max-width: 767px) {
    background-color: #f2f2f2;
    .navigation {
      background-color: #ffffff;
    }

    .header {
      background-color: #ffffff;
      border-bottom: none;
      font-weight: 400; // normal
      margin-bottom: 10px;

      .info {
        font-size: 14px;
        text-transform: capitalize;

        &.border {
          border-bottom: solid 1px $border-color;
          padding-bottom: 10px;
        }
      }

      .title {
        border-bottom: solid 1px $border-color;
        padding: 0px 0px 0px 10px;
      }
    }

    .status {
      border: none;
      background-color: #ffffff;
      margin: 10px 0px 10px 0px;

      .dropdown {
        border: solid 1px $border-color;
        border-radius: 4px;
        padding: 5px 5px 5px 0px;
        width: 100%;

        button {
          text-align: left;
          width: 100%;
        }
        .dropdown-menu {
          width: 100%;
        }
      }
    }
  }
}
