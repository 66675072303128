#top-bar {
  box-shadow: 5px 2px 5px rgba(0,0,0,.1);
  border: 0;
  .menu-item {
    margin-right: 0;
    padding: 0;
    a {
      padding: 5px;
      padding-right: 10px;
    }
  }
}

.navbar {
  background-color: #ffffff;
  border-bottom: solid 1px $border-color;
  border-radius: 0px;
  font-size: 13px;
  margin-bottom: 0px;

  .unread-count {
    position: absolute; 
    color: #fff; 
    background-color: #a51890;
    font-size: .5em; 
    border: 1px solid #a51890; 
    margin: 0; 
    padding: 0; 
    line-height: 0; 
    min-width: 12px; 
    height: 12px;
    display: flex;
    border-radius: 15px;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    padding-left: 3px;
    padding-right: 3px;
    top: -2px;
    left: 14px;
  }

  .bell-loader {
    opacity: .4;
    position: absolute;
    background-color: #a51890;
    border: 1px solid #a51890; 
    font-size: .5em; 
    width: 30%; 
    height: 1;
    bottom: 0;
    left: 0;
    animation-name: bell-loader;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: ease-in-out;
  }

  .bgproc-event-loader {
    opacity: .4;
    position: relative; 
    background-color: #a51890;
    border: 1px solid #a51890; 
    font-size: .5em; 
    width: 30%;
    height: 1;
    bottom: 0;
    left: 0;
    animation-name: bell-loader;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: ease-in-out;
    top: 2;
  }

  @keyframes bell-loader {
    0%   {margin-left: 0;}
    100%  {margin-left: 70%;}
  }

  .background-process-dropdown {
    padding-top: 4px !important;
    .glyphicon {
      vertical-align: middle;
      padding-bottom: 3px;
    }
    .dropdown-toggle {
      padding: 2px;
    }
    ul {
      min-width: 240px;
      padding-top: 0;
      padding-bottom: 0;
      max-height: 640px;
      overflow-y: auto;
      scrollbar-width: thin;
      a {
        padding: 0px !important;
        color: black;
        text-decoration: none !important;
      }
    }
  }

  #bgproc-event-types {
    font-size: 0.9em;
    display: block;
    padding: 6px !important;
    box-shadow: 0 0 10px rgba(0,0,0,.2);
    color: #222;
  }

  li {
    margin-right: 20px;
    .menu-item {
      padding: 5px;
    }
  }

  li > a {
    padding: 0px;

    &:focus {
      outline: none;
    }
  }

  .btn-round-wrapper {
    display: inline-block;
    margin-top: -10px;

    .btn {
      border-radius: 17px;
      min-width: 37px;
      height: 37px;
      border: 0;

      svg {
        margin-top: 3px;
        width: 12px;
      }

    }

  }

  .navbar-header {
    .navbar-brand {
      padding-bottom: 10px;
      padding-top: 10px;
    }
  }

  .navbar-collapse {
    .nav {
      padding-top: 15px;

      .dropdown {
        padding-top: 7px;
      }

      .socket-indicator {
        padding-top: 7px;

        &.open {
          color: green;
        }

        &.connecting,
        &.disconnecting {
          color: orange;
        }

        &.closed {
          color: red;
        }
      }

      .worktime {
        padding-left: 10px;

        button {
          border: none;
          height: 30px;
          outline: none;
          padding: 0px;
          text-align: center;
          width: 30px;

          .glyphicon {
            color: $bookers-color;
            font-size: 20px;
            margin-right: 0px;
          }
        }

        span {
          margin-right: 5px;
        }
      }
    }

    #with-project {
      padding-top: 4px;

      li:nth-child(1) > a > div {
        padding-top: 15px;
      }

      .nav-dropdown {
        display: flex;
      }

      #title-wrap {
        display: flex;
        height: 36px;
        align-items: center;

        #project-name {
          font-size: 0.9em;
          padding-top: 0;
          margin-top: -5px;
          line-height: 1.2em;
        }

        #username {
          font-size: 1.1em;
          font-weight: bold;
        }
      }
    }
  }

  .show-only-on-xs {
    display: none;
  }

  @media (max-width: 767px) {
    li > a {
      margin-left: 0px;
      padding: 5px;
    }

    .navbar-collapse {
      overflow: initial;
      .nav {
        padding-top: 0px;

        .btn-round-wrapper {
          margin-top: 0;
          margin-left: 5px;
        }

        .worktime {
          padding-left: 0px;
        }
      }
    }

    .show-only-on-xs {
      display: block;
    }
  }
}
