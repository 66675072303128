// Base colors
$bookers-color: #a51890;
$border-color: #d6d6d6;

// Event colors
$event-bg-color-free: #f5f5f5;
$event-bg-color-bookable: $event-bg-color-free;
$event-bg-color-created: $bookers-color;
$event-bg-color-not-confirmed: $event-bg-color-created;
$event-bg-color-outcome-unmarked: $event-bg-color-created;
$event-bg-color-rescheduled: $event-bg-color-created;
$event-bg-color-deal: #aff9cf;
$event-bg-color-no-deal: #ffdede;
$event-bg-color-deal-proposed: #fff1b8;
$event-bg-color-follow-up: $event-bg-color-deal-proposed;
$event-bg-color-reserved: #c7e9ff;
$event-bg-color-tentative: $bookers-color;
$event-bg-color-no-show: #f7f7f7;
$event-bg-color-personal: #fff0e3;
$event-bg-color-external: #dae0f1;

$event-color-free: #666666;
$event-color-bookable: $event-color-free;
$event-color-created: $bookers-color;
$event-color-not-confirmed: $event-color-created;
$event-color-outcome-unmarked: $event-color-created;
$event-color-rescheduled: $event-color-created;
$event-color-deal: #00883a;
$event-color-no-deal: #c0392c;
$event-color-deal-proposed: #c27701;
$event-color-follow-up: $event-color-deal-proposed;
$event-color-reserved: #2980B9;
$event-color-tentative: $bookers-color;
$event-color-no-show: #666666;
$event-color-personal: #d35400;
$event-color-external: #556490;

$event-type-color-call: rgb(255, 130, 255);
$event-type-color-remote: rgb(130, 255, 130);
$event-type-color-servicePoint: rgb(255, 130, 130);
$event-type-color-online: rgb(130, 130, 255);

// Bootstrap-sass font path
$icon-font-path: "../../../node_modules/bootstrap-sass/assets/fonts/bootstrap/";

// Breakpoints
$breakpoint-narrow: 667px;
$breakpoint-medium: 1000px;
