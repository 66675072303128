.event {
  .created {
    border-top: solid 1px $border-color;
    font-size: 13px;
    padding: 10px;
    text-align: left;
  }

  .navigation {
    border-bottom: solid 1px $border-color;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 40px;
    justify-content: space-between;

    .btn {
      border: none;
      outline: none;

      .back {
        padding-left: 5px;
        text-transform: capitalize;
      }
    }
  }

  .heading {
    background-color: #ffffff;
    font-size: 20px;
    font-weight: 400; // normal
    margin-bottom: 10px;
    padding: 10px 0 10px 10px;
    text-align: left;

    .date {
      font-size: 14px;
      text-transform: capitalize;
    }

    .title {
      border-bottom: solid 1px $border-color;
      min-height: 35px;
      margin-bottom: 10px;
    }
  }

  .recurrence {
    font-size: 14px;
    padding: 10px 0 10px 10px;
    text-align: left;

    span {
      margin-right: 5px;
    }
  }

  .description {
    padding: 10px 0 10px 10px;
    text-align: left;
  }

  @media (max-width: 767px) {
    background-color: #f2f2f2;

    .navigation {
      background-color: #ffffff;
      border-bottom: none;
      margin-bottom: 10px;

      .btn {
        padding-left: 10px;
        padding-right: 10px;
      }
    }

    .heading {
      background-color: #ffffff;
      font-weight: 400; // normal
      margin-bottom: 10px;
      padding-right: 10px;

      .date {
        font-size: 14px;
        text-transform: capitalize;
      }

      .title {
        border-bottom: solid 1px $border-color;
        min-height: 35px;
        margin-bottom: 10px;
      }
    }

    .description {
      background-color: #ffffff;
      padding-right: 10px;
    }
  }
}
