.project-settings {
    .chevron {
        float: right;
    }

    .subheading {
        &:not(.no-collapse) {
            cursor: pointer;
            font-weight: bold;    
        }
        background: linear-gradient(180deg, rgba(0,0,0,.01) 0%, rgba(255,255,255,1) 100%, rgba(0,0,0,1) 100%);
    }

    .input-row {
        display: flex;
        flex-flow: row;
        margin-bottom: 5px;
    }

    .input-text, .input-textarea {
        height: 30px;
        padding-left: 7px;
        border: solid 1px #d6d6d6;
        border-radius: 4px;
        width: 80%;
        min-width: 180px;
        max-width: 300px;
        .narrow {
            min-width: 90px;
            max-width: 150px;      
        }
    }

    .input-textarea {
        max-width: 1024px;
        height: 60px;
        width: 350px;
    }

    .input-label {
        width: 220px;
        min-width: 220px;
        display: flex;
        align-items: center;
        padding-right: 5px;
    }

    .table {
        thead {
            th {
                border-bottom: 0;
            }
        }
        td {
            vertical-align: middle;
        }
    }

    .btn-primary-0 {
        color: #ffffff;
        background-color: #a51890;
        border-color: #a51890;
        font-size: 16px;
        margin-right: 10px;
        margin-bottom: 5px;  
    }

    .dual-panel {
        height: 100%;
        padding-bottom: 0;
        display: flex;
        flex-flow: row;  
        min-height: 250px;
        .left-panel, .right-panel {
            flex: 1 1 auto;
            display: flex;
            flex-flow: column;
            border: 1px solid #ddd;
            border-radius: 5px 0 0 5px;
            overflow: hidden;
        }
        .right-panel {
            border-width: 1px 1px 1px 0;
            border-radius: 0 5px 5px 0;
        }
        select {
            flex: 1 1 auto;
            border: 0;
            outline: 0;
            overflow: auto;
            width: 100%;
        }
    }
}