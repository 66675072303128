.modal-content input {
  background-color: #ffffff;
  border: solid 0.6px #b2b2b2;
  border-radius: 10px;
  font-size: 16px;
  height: 42px;
  margin-bottom: 10px;
  outline: none;
  padding: 5px 15px 5px 15px;
  text-align: center;
  width: 280px;
}

.login {
  background-image: url('../images/login.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100vh; // viewport height
  justify-content: space-between;

  .form {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
    height: 345px;
    margin: 50px 50px 0px 0px;
    padding: 30px 20px 40px 20px;
    text-align: center;
    width: 320px;

    .heading {
      font-size: 30px;
      font-weight: bold;
      margin-bottom: 20px;
    }

    button {
      background-color: $bookers-color;
      border: none;
      border-radius: 10px;
      color: white;
      font-size: 16px;
      height: 50px;
      margin-top: 10px;
      outline: none;
      width: 160px;

      &:focus {
        outline: none;
      }
      &:hover {
        background-color: $bookers-color;
      }
    }

    input[type='text'],
    input[type='password'] {
      background-color: #ffffff;
      border: solid 0.6px #b2b2b2;
      border-radius: 10px;
      font-size: 16px;
      height: 42px;
      margin-bottom: 10px;
      outline: none;
      padding: 5px 15px 5px 15px;
      text-align: center;
      width: 280px;
    }
  }

  img {
    padding: 50px;
    width: 300px;
  }

  @media (max-width: 767px) {
    flex-flow: column;
    justify-content: center;

    .form-container {
      margin: auto;
      margin-top: 50px;
    }

    .form {
      background-color: rgba(255,255,255,1);
      margin: 0px;
    }

    img {
      padding: 30px;
    }
  }
}
