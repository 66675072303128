.edit-event {
  .back {
    border-bottom: solid 1px $border-color;
    height: 50px;
    padding-top: 10px;
    text-align: left;

    .btn {
      border: none;
      outline: none;

      .date {
        padding-left: 5px;
        text-transform: capitalize;
      }
    }
  }

  .event-actions {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    padding: 10px;

    button {
      outline: none;
      margin-right: 10px;
      min-width: 120px;
    }
  }

  .event-row {
    border-bottom: solid 1px $border-color;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px;
    width: 100%;

    .event-all-day {
      margin-top: 10px;
      width: 100% !important;
      height: 40px;
    }

    .event-row-column, .event-all-day {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      width: 49%;

      &:nth-of-type(2),
      &:nth-of-type(3),
      &:nth-of-type(4),
      &:nth-of-type(5),
      &:nth-of-type(6) {
        margin-top: 10px;
      }

      input {
        border: solid 1px $border-color;
        border-radius: 4px;
      }

      input[type='text'] {
        height: 40px;
        padding-left: 10px;
        width: 100%;
      }

      input[type='time'] {
        border: solid 1px $border-color;
      }

      span {
        align-self: flex-start;
        margin-right: auto;
        padding: 10px 10px 0px 10px;
      }

      textarea {
        border: solid 1px $border-color;
        border-radius: 4px;
        height: 90px;
        padding: 10px 10px 0px 10px;
        resize: none;
        width: 100%;
      }

      .date-picker-container {
        border: solid 1px $border-color;
        border-radius: 4px;
        margin-right: 10px;
      }

      &.all-day {
        .date-picker-container {
          margin-right: 0px;
        }
      }

      .dropdown {
        width: 100%;

        button {
          border: solid 1px $border-color;
          border-radius: 4px;
          text-align: left;
          width: 100%;
        }

        .dropdown-menu {
          position: static;
          width: 100%;
        }
      }
    }
  }

  .navigation {
    border-bottom: solid 1px $border-color;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 40px;
    justify-content: space-between;
  }

  @media (max-width: 767px) {
    background-color: #f2f2f2;

    .event-actions {
      background-color: #ffffff;
      justify-content: center;

      button {
        margin: 0px;
      }
    }

    .event-row {
      border-bottom: none;
      flex-wrap: wrap;
      padding: 0px;

      .event-row-column, .event-all-day {
        background-color: #ffffff;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-end;
        margin-bottom: 10px;
        width: 100%;

        input[type='text'] {
          border: none;
          height: 40px;
          padding: 0px 10px 0px 10px;
          width: 100%;
        }

        input[type='time'] {
          border: none;
          padding: 0px 10px 10px 10px;
        }

        span {
          align-self: flex-start;
          margin-right: auto;
          padding: 5px 10px 0px 10px;
        }

        textarea {
          border: none;
          height: 60px;
          padding: 10px 10px 0px 10px;
          width: 100%;
        }

        .dropdown {
          padding: 5px;
          width: 100%;

          button {
            border: none;
          }

          .dropdown-menu {
            width: 100%;
          }
        }
      }
    }

    .navigation {
      background-color: #ffffff;
      border-bottom: none;
      margin-bottom: 10px;

      .btn {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
}
