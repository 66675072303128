.representative-info {
  .rep-info-row {
    border-bottom: solid 1px $border-color;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 0px;

    button {
      border: none;
      font-size: 15px;
      font-weight: 700; // bold
      height: 100%;
      padding: 5px 10px 5px 5px;
      text-align: left;
      width: 100%;
    }

    div {
      padding: 5px;
      text-align: left;

      &:nth-of-type(1) {
        border-right: solid 1px $border-color;
        width: 25%;
        > div {
          padding: 0;
          width: 100%;
          border: 0;
        }
      }
    }

    img {
      height: 32px;
      margin: 5px 10px 2px 0px;
    }

    // Second info row
    &:nth-of-type(2) {
      div {
        &:nth-of-type(1) {
          border-right: solid 1px $border-color;
          width: 25%;
        }
        &:nth-of-type(2) {
          border-right: solid 1px $border-color;
          text-align: left;
          width: 35%;
        }
        &:nth-of-type(3) {
          width: 40%;
        }
      }
    }

    .rep-info-row-column {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      padding: 5px 5px 5px 10px;

      span {
        &:nth-of-type(1) {
          font-weight: 700; // bold
        }
      }
    }
  }

  @media (max-width: 767px) {
    .rep-info-row {
      border-bottom: none;
      flex-wrap: wrap;
      height: 50px;

      &:nth-of-type(2) {
        height: 150px; // triple
      }

      div {
        border-bottom: solid 1px $border-color;
        flex-basis: 100%;
      }

      img {
        display: none;
      }
    }
  }
}
