.copy-booking {

  &.day {
    background-color: white;

    .heading {
      .dropdown {
        #move-to-representative {
          border: 0 !important;
          width: 100% !important;
        }
        .dropdown-menu {
          right: 0;
          left: auto;
          min-width: 50px;
        }
      }
    }
  
  }

  .time-picker {
    min-width: 100px;
  }

  #move-to-representative {
    height: 40px;
    padding: 10px !important;

    .caret {
      padding: 0;
    }
  }

  .date-row {
    border-bottom: solid 1px $border-color;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px;
    width: 100%;

    .date-row-column {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      width: 49%;

      input {
        border: solid 1px $border-color;
        border-radius: 4px;
      }

      input[type='text'] {
        height: 40px;
        padding-left: 10px;
        width: 100%;
      }

      input[type='time'] {
        border: solid 1px $border-color;
      }

      span {
        align-self: flex-start;
        margin-right: auto;
        padding: 10px 10px 0px 0px;
      }

      .date-picker-container {
        border: solid 1px $border-color;
        border-radius: 4px;
        margin-right: 10px;
        text-align: left;
        width: 50%;

        span {
          padding: 0;
        }
      }
    }
  }

  .header {
    border-bottom: solid 1px $border-color;
    font-weight: 400; // normal
    padding: 10px;
    text-align: left;
    font-size: 20px;
  }

  .navigation {
    border-bottom: solid 1px $border-color;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 40px;
    justify-content: space-between;
  }

  @media (max-width: 767px) {
    background-color: #f2f2f2;

    .date-row {
      border-bottom: none;
      flex-wrap: wrap;
      padding: 0px;

      .date-row-column {
        background-color: #ffffff;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-end;
        margin-bottom: 10px;
        width: 100%;

        input[type='text'] {
          border: none;
          height: 40px;
          padding: 0px 10px 0px 10px;
          width: 100%;
        }

        input[type='time'] {
          border: none;
          padding: 0px 10px 10px 10px;
        }

        span {
          align-self: flex-start;
          margin-right: auto;
          padding: 5px 10px 0px 10px;
        }
      }
    }

    .header {
      background-color: #ffffff;
      border-bottom: solid 1px $border-color;
      font-weight: 400; // normal
      padding: 10px;
      text-align: left;
      font-size: 20px;
    }

    .navigation {
      background-color: #ffffff;
      border-bottom: none;
      margin-bottom: 10px;

      button {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
}
