.booking-calendar.week {
  .rbc-calendar {
    .rbc-toolbar {
      margin-top: 0.3em;
    }

    .rbc-event:not(.free) {
      cursor: default;
    }
  }

  .booking-calendar-actions {
    display: flex;
    justify-content: space-between;
    padding-top: 11px;
  
    .btn-link {
      margin-left: -2px;
      padding-left: 0;
    }

    .dropdown {
      flex: 1;
      max-width: 200px;
      border: solid 1px $border-color;
      border-radius: 4px;
      padding: 5px;
      background-color: white;

      .dropdown-toggle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        border: none;
        text-align: left;
      }

      .dropdown-menu {
        width: 100%;
      }
    }
  }
}
