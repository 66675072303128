.suggestions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .actions-bottom {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    margin: 20px 20px 10px 10px;

    button {
      height: 35px;
      outline: none;
      width: 120px;

      &:focus {
        outline: none;
      }
    }
  }

  .actions-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 15px 10px 15px 10px;

    button {
      height: 35px;
      width: 100px;

      &:focus {
        outline: none;
      }
    }

    .filters {
      button {
        margin-right: 5px;
      }
    }
  }

  .days {
    border-bottom: solid 1px $border-color;
    border-top: solid 1px $border-color;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: calc(100vh - 450px); // 350px is approx other content on page
    justify-content: flex-start;
    min-height: 300px;
    overflow-y: scroll;
    padding: 10px;

    .days-wrapper {
      margin: 20px 10px 10px 10px;
      position: relative;
      width: 100%; 
      display: flex;
    }

    &.weekbar {
      position: absolute;
      top: -20px;
      width: 100%; 
      padding: 0; 
      min-height: 0; 
      height: unset;
      overflow: hidden;
      border: 0;

      .day {
        text-align: left;
        padding-left: 5px;
        > span {
          font-size: 11px; 
          font-weight: bold;
        }
      }  

    }

    .day {
      margin-right: 10px;
      width: 19%;

      .heading {
        font-size: 13px;
        justify-content: center;
        margin-bottom: 10px;
        text-transform: capitalize;
      }
    }
  }

  .content {
    width: 100%;
  }

  .side-bar {
    border-right: solid 1px $border-color;
    max-width: 275px;
  }

  @media (max-width: 767px) {
    .actions-top {
      flex-wrap: wrap;

      .filters {
        display: none;
      }
    }

    .days {
      .day {
        width: 39%;

        .heading {
          font-size: 12px;
        }
      }
    }

    .side-bar {
      display: none;
    }
  }
}
