.date-picker {
  border-radius: 10px;
  cursor: pointer;
  padding: 10px;
  vertical-align: middle;

  &.focus {
    outline: none;
  }
}

.date-picker-container {
  margin-left: -1px; // Hide the underlying input element border

  .DayPicker-NavButton {
    outline: none;

    &.DayPicker-NavButton--prev {
      margin-right: 2.5rem !important;
    }
  }

  .DayPicker-wrapper {
    border: solid 1px $border-color;
    border-radius: 4px;
  }
}